import { FormikTouched } from 'formik'
import { useEffect, useState } from 'react'
import { fetchBusinessData } from 'src/services/business-data'
import { ISubscribeFields } from '.'

const useBusinessDataAutoFill = (
  values: ISubscribeFields,
  touched: FormikTouched<ISubscribeFields>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  enabled: boolean
) => {
  const [pending, setPending] = useState(false)

  const loadBusinessData = async (businessCode: string, country: 'FR' | 'BE') => {
    setPending(true)

    try {
      const response = await fetchBusinessData(businessCode)

      if (response.code !== 200 || !response.data) {
        throw new Error()
      }

      const result = response.data

      const assignValue = (field: string, value: any) => {
        if (value) {
          setFieldValue(field, value)
        }
      }

      assignValue('name', result.name)

      if (country === 'BE') {
        assignValue('naf', result.activityCode)
      } else {
        assignValue('ape', result.activityCode)
      }

      assignValue('job', result.manager.position)


      assignValue('firstname', result.manager.firstname)


      assignValue('lastname', result.manager.lastname)


      assignValue('businessDescription', result.activity)
      assignValue('address', result.address)
      assignValue('addressNumber', result.addressNumber)
      assignValue('city', result.city)
      assignValue('zipCode', result.zipCode)
    } catch (error) {
      console.log({ error })
    }

    setPending(false)
  }

  useEffect(() => {
    if (enabled && values.tva && /^BE[0-9]{10}$/i.test(values.tva)) {
      loadBusinessData(values.tva, 'BE')
    }
  }, [values.tva])

  useEffect(() => {
    if (enabled && values.siren && /^[0-9]{9}$/i.test(values.siren)) {
      loadBusinessData(values.siren, 'FR')
    }
  }, [values.siren])

  return {
    pending,
  }
}

export default useBusinessDataAutoFill
