import { IFeathersResponse } from "src/types/feathers.types"
import { IFeathersDefaultFilters } from "src/types/feathers.types"
import fetchService from "src/utils/fetchToken"

const subscribeEndpoint = `${process.env.REACT_APP_API_URL}`

const endPoints = {
  deposit: `${subscribeEndpoint}/deposits`,
}

export interface Deposit {
  id: number
  ref: string
  name: string
  address: string
  addressCplt: string | null
  zipCode: string
  city: string
  country: string
  phone: string
  lat: number | null
  lng: number | null
  email: string | null
  enabled: boolean
}

export const fetchAllDeposits = async (): Promise<Deposit[]> => {
  const result = await fetch(`${endPoints.deposit}?$limit=-1`)

  return result.json()
}

export const fetchDeposits = async (filters?: IFeathersDefaultFilters): Promise<IFeathersResponse<Deposit>> => {
  return fetchService(`${endPoints.deposit}`, 'GET', filters)
}

export const getDeposit = async (depositId: number): Promise<Deposit> => {
  const result = await fetch(`${endPoints.deposit}/${depositId}`)
  return result.json()
}

export interface Operator {
  id: number
  firstName: string
  lastName: string
  email: string
  depotCode: string
}
