import { useEffect } from 'react'

const jiraUrl =
  'https://whatsonio.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-dtzt95/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=9707ee8d'

const useJiraScript = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = jiraUrl
    script.async = true

    const isStaging = process.env.REACT_APP_ENVIRONMENT === 'staging'

    if (isStaging) {
      document.body.appendChild(script)
    }

    return isStaging
      ? () => {
          document.body.removeChild(script)
        }
      : undefined
  }, [])
}

export default useJiraScript
