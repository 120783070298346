import { logout } from '../../stores/login/actions'
import { useStore } from '../../stores/index'
import { Link, useHistory, useLocation } from 'react-router-dom'
import routes from 'src/routes'

import { useState } from 'react'

interface ConnectedMenuProps { }

const ConnnectedMenu: React.FC<ConnectedMenuProps> = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleSideBar = () => {
    setIsOpen(!isOpen)
  }

  const history = useHistory()
  const { dispatch, state } = useStore()

  const onLogOut = () => {
    if (window.confirm('êtes-vous sure de vouloir être déconnecté?')) {
      dispatch(logout())
      alert('Vous avez été déconnecté avec succès')
      history.push(`${routes.LOGIN}`)

    }
  }

  const { pathname } = useLocation()




  return (
    <header>
      <div className="" style={{ maxWidth: '980px', margin: 'auto' }}>
        <nav className="flex justify-between py-8 mx-2.5 lg:mx-0 bg-white items-center">
          <a id="logo" href="/" style={{ textIndent: -10000 }}>
            Logo
          </a>
          <button onClick={toggleSideBar} className="pr-1">
            <div className="flex lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </div>
          </button>
          <div
            className={`flex mb-0 flex-column lg:flex-row lg:justify-end space-x-0 lg:space-x-8 space-y-5 lg:space-y-0
                    p-3 lg:p-0 items-center transform lg:translate-x-0 lg:static lg:inset-0 bg-white border-l border-gray-200 border-solid
                    lg:border-l-0 shadow-lg lg:shadow-none
                    ${isOpen ? '-translate-x-0 ease-out flex-col' : 'hidden space-x-8 lg:flex '
              } fixed inset-y-0 right-0 z-30 w-48 overflow-y-auto transition duration-100`}
          >
            <button className="block lg:hidden mt-10 mb-5" onClick={toggleSideBar}>
              <svg
                className="h-10 w-10 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            <a href="https://particuliers.howdens-cuisines.com" className="mt-8 lg:mt-0">
              Site Grand Public
            </a>
            <a href="https://pro.howdens-cuisines.com">Espace Pro</a>
            {state.login.currentUser && (
              <>
                {state.login.depositRef !== null && (

                  <Link
                    to={`${routes.DEPOSIT_FORM}/${state.login.depositRef}`}
                    className={`${pathname === routes.DEPOSIT_FORM ? 'actif' : ''}`}
                  >
                    Mon dépôt
                  </Link>
                )}
                <Link to={routes.DEPOSITS} className={`${pathname === routes.DEPOSITS ? 'actif' : ''}`}>
                  Liste dépôts
                </Link>
                <button
                  className="logout bg-red-600 text-white rounded-md py-2 px-4 inline ml-5 cursor-pointer hover:opacity-70 "
                  onClick={onLogOut}
                >
                  Déconnexion
                </button>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  )
}

export default ConnnectedMenu
